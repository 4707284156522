export const simpleHash = (str = '', shouldSort = false) => {
  const s = str.split('')

  if (shouldSort) {
    s.sort()
  }

  return s.reduce((hash, character) => {
    const h = (hash << 5) - hash + character.charCodeAt(0)
    return h & h // Convert to 32bit integer
  }, 0)
}
