const STREAM_API_PREFIX = '/attask/api-internal/'
const SPRING_CONTROLLER_PREFIX = '/internal/'
const UI_DATA_PREFIX = '/ui-data/'

export function isQssUiDataUrl(url) {
  return url.startsWith(UI_DATA_PREFIX)
}

export function isStreamApiUrl(url) {
  return url.startsWith(STREAM_API_PREFIX)
}

export function isSpringControllerUrl(url) {
  return url.startsWith(SPRING_CONTROLLER_PREFIX)
}

export function isRedRockUrl(url) {
  return (
    isStreamApiUrl(url) || isSpringControllerUrl(url) || isQssUiDataUrl(url)
  )
}
