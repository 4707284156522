import { simpleHash } from './simpleHash.js'

const WORKFRONT_OPTIONS_FALLBACK = {}

export function getCacheKey(
  url,
  fetchOptions = {},
  workfrontOptions = WORKFRONT_OPTIONS_FALLBACK,
) {
  let cacheKey
  try {
    const { signal: _signal, headers, ...stringifiableOptions } = fetchOptions
    const { 'x-request-id': _xRequestId, ...nonUniqueHeaders } = headers

    const fetchOptionsStr = JSON.stringify({
      headers: nonUniqueHeaders,
      ...stringifiableOptions,
    })
    const workfrontOptionsStr = JSON.stringify(
      workfrontOptions === WORKFRONT_OPTIONS_FALLBACK
        ? WORKFRONT_OPTIONS_FALLBACK
        : {
            timeToExpiration: workfrontOptions.timeToExpiration,
            isDataSensitive: workfrontOptions.isDataSensitive,
            initialRequest: workfrontOptions.initialRequest ? 1 : 0,
          },
    )

    cacheKey = simpleHash(
      `${url}${fetchOptionsStr}${workfrontOptionsStr}`,
      true,
    )
  } catch (e) {
    cacheKey = url
  }

  return cacheKey
}
