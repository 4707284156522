import { useEffect, useReducer, useRef } from 'react'

import { wfetch } from './wfetch.js'

const FETCH_ERROR = Symbol('FETCH_ERROR')
const FETCH_START = Symbol('FETCH_START')
const MANUAL_ABORT = Symbol('MANUAL_ABORT')
const MANUAL_REQUEST = Symbol('MANUAL_REQUEST')
const RESULTS_RETURNED = Symbol('RESULTS_RETURNED')
const ABORT_NOOP = () =>
  console.log('Cannot abort, fetch call finished or not yet initiated')
const REQUEST_NOOP = () =>
  console.log(
    'Cannot make a manual request without the manualRequest option being set',
  )
const REFETCH_NOOP = () =>
  console.log('Cannot refetch until the original fetch call has succeeded')

/**
 * React hook that works with wfetch which is a
 * wrapped fetch call that can communicate with the Stream API,
 * Spring Controller and other endpoints.
 * Redirects to login if user is not authenticated.
 * @param {string} url   endpoint url to make the request to
 * @param {RequestInit} [fetchOptions]   fetch-compatible options object
 * @param {object} [workfrontOptions]   Workfront-specific options object
 * @returns {Object}   Object with the following properties: an abort function to stop the request, data if the request has completed successfully, error if the request has failed, inProgress if the request is still in progress, loaded if the request has completed successfully, makeRequest if manualRequest option has been set.
 */
export function useWFetch(url, fetchOptions = {}, workfrontOptions = {}) {
  const mounted = useRef(true)
  useEffect(
    () => () => {
      mounted.current = false
    },
    [],
  )

  const inProgressInternal = useRef(false)
  const controller = useRef()

  const [state, dispatch] = useReducer(wfetchReducer, {
    error: null,
    loaded: false,
    inProgress: true,
    data: null,
    abort: ABORT_NOOP,
    makeRequest: REQUEST_NOOP,
    refetch: REFETCH_NOOP,
  })

  const fetchOptionsString = JSON.stringify(fetchOptions)
  const { initialRequest, ...remainingWorkfrontOptions } = workfrontOptions
  const workfrontOptionsString = JSON.stringify(remainingWorkfrontOptions)

  useEffect(() => {
    function abortFunction() {
      if (inProgressInternal.current) {
        controller.current?.abort()
        dispatch({ type: MANUAL_ABORT })
      }
    }

    const fetchOptionsReparsed = JSON.parse(fetchOptionsString)
    const { manualRequest, ...workfrontOptionsReparsed } = JSON.parse(
      workfrontOptionsString,
    )

    async function makeRequest(refetching = false) {
      controller.current = new AbortController()

      try {
        dispatch({ type: FETCH_START, abortFunction })
        inProgressInternal.current = true
        const data = await wfetch(
          url,
          {
            ...fetchOptionsReparsed,
            signal: controller.current.signal,
          },
          {
            ...workfrontOptionsReparsed,
            ...(refetching ? {} : { initialRequest }),
            refetching,
          },
        )

        inProgressInternal.current = false

        if (mounted.current) {
          dispatch({
            type: RESULTS_RETURNED,
            data,
            refetch: () => makeRequest(true),
          })
        }
      } catch (error) {
        dispatch({ type: FETCH_ERROR, error })
      }
    }

    if (manualRequest) {
      dispatch({ type: MANUAL_REQUEST, makeRequest })
    } else {
      makeRequest()
    }

    return abortFunction
  }, [url, fetchOptionsString, workfrontOptionsString, initialRequest])

  return state
}

function wfetchReducer(state, action) {
  switch (action.type) {
    case FETCH_START:
      return {
        ...state,
        abort: action.abortFunction,
        data: null,
        error: null,
        inProgress: true,
        loaded: false,
        makeRequest: REQUEST_NOOP,
        refetch: REFETCH_NOOP,
      }
    case RESULTS_RETURNED:
      return {
        ...state,
        abort: ABORT_NOOP,
        data: action.data,
        error: null,
        inProgress: false,
        loaded: true,
        makeRequest: REQUEST_NOOP,
        refetch: action.refetch,
      }
    case FETCH_ERROR:
      return {
        abort: ABORT_NOOP,
        data: null,
        error: action.error,
        inProgress: false,
        loaded: false,
        makeRequest: REQUEST_NOOP,
        refetch: REFETCH_NOOP,
      }
    case MANUAL_ABORT:
      return {
        abort: ABORT_NOOP,
        data: null,
        error: null,
        inProgress: false,
        loaded: false,
        makeRequest: REQUEST_NOOP,
        refetch: REFETCH_NOOP,
      }
    case MANUAL_REQUEST:
      return {
        abort: ABORT_NOOP,
        data: null,
        error: null,
        inProgress: false,
        loaded: false,
        makeRequest: action.makeRequest,
        refetch: REFETCH_NOOP,
      }
    default:
      return state
  }
}
