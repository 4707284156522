import { getAuthorizationHeader, getCredentialsSetting } from './configuration'
import {
  GET,
  POST,
  PUT,
  URI_FIELD_FORM_ENCODING_FIX_REGEX,
} from './constants.js'
import { encodeAsFormData } from './encodeAsFormData.js'
import {
  FORM_CONTENT_TYPE_HEADER,
  getAcceptLanguageHeader,
  getRedrockHeaders,
  getRequestIdHeaders,
} from './headers.js'
import { isSpringControllerUrl, isStreamApiUrl } from './requestTypes.js'
import { hasFormHeader, hasJSONHeader } from './utilities.js'

export default function applyDefaultsAndMergeOptionsOverrides(
  url,
  fetchOptions,
  tracking,
) {
  if (isStreamApiUrl(url)) {
    return getStreamApiConfig(fetchOptions, tracking)
  }

  if (isSpringControllerUrl(url)) {
    return getSpringControllerConfig(fetchOptions)
  }

  return getAllOtherConfig(fetchOptions)
}

function getMethod(fetchOptions) {
  if (fetchOptions.method === undefined) {
    return fetchOptions.body === undefined ? GET : POST
  }

  return fetchOptions.method
}

function getStreamApiConfig(fetchOptions, tracking) {
  let body = fetchOptions.body

  const method = getMethod(fetchOptions)
  const credentials = getCredentialsSetting()
  const headers = {
    ...getRequestIdHeaders(),
    ...getRedrockHeaders(),
    ...getAcceptLanguageHeader(),
    ...FORM_CONTENT_TYPE_HEADER,
    ...fetchOptions.headers,
  }

  const bodyIsAlreadyFormatted = typeof body === 'string'

  if (!bodyIsAlreadyFormatted && method !== GET) {
    if (hasFormHeader(headers)) {
      body = addMethodToBody(method, body)

      if (body.method) {
        tracking.method = body.method
      }

      body = encodeAsFormData(body).replace(
        URI_FIELD_FORM_ENCODING_FIX_REGEX,
        'uri',
      )
    } else if (hasJSONHeader(headers)) {
      body = JSON.stringify(body)
    }
  }

  return {
    ...fetchOptions,
    body,
    credentials,
    headers,
    method,
  }
}

function getSpringControllerConfig(fetchOptions) {
  const method = getMethod(fetchOptions)
  const credentials = getCredentialsSetting()

  return {
    ...fetchOptions,
    body: fetchOptions.body,
    credentials,
    method,
    headers: {
      ...getRequestIdHeaders(),
      ...getRedrockHeaders(),
      ...getAcceptLanguageHeader(),
      ...fetchOptions.headers,
    },
  }
}

function getAllOtherConfig(fetchOptions) {
  const authorizationHeader = getAuthorizationHeader()
  if (authorizationHeader) {
    return {
      ...fetchOptions,
      headers: {
        ...getRequestIdHeaders(),
        ...fetchOptions.headers,
        ...getAcceptLanguageHeader(),
        Authorization: authorizationHeader,
      },
    }
  }

  return fetchOptions
}

function addMethodToBody(method, body = {}) {
  if (body.method === undefined) {
    if (body.action) {
      body.method = PUT
    } else {
      body.method = method
    }
  }

  return body
}
