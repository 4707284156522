const defaultConfiguration = {
  authorizationHeader: undefined,
  credentials: 'include',
  rootUrl: '',
}

const configuration = Object.assign({}, defaultConfiguration)

export function configure(options) {
  Object.assign(configuration, options)
}

export function reset() {
  Object.assign(configuration, defaultConfiguration)
}

export function getWorkfrontRootApiUrl() {
  return configuration.rootUrl
}

export function getAuthorizationHeader() {
  return configuration.authorizationHeader
}

export function getCredentialsSetting() {
  return configuration.credentials
}
