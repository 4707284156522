import { getSessionID, getXSRFToken } from 'workfront-cookie'

import { getAuthorizationHeader } from './configuration'
import { CONTENT_TYPE_FORM, CONTENT_TYPE_JSON } from './constants.js'

/**
 * @typedef {Object} WfTokenBasedAuthHeaders
 * @property {string} X-XSRF-TOKEN
 *
 * @typedef {Object} WfSessionBasedAuthHeaders
 * @property {string} sessionID
 *
 * @typedef {WfTokenBasedAuthHeaders & WfSessionBasedAuthHeaders} WfAuthHeaders
 */

/**
 * Returns a set of headers required for authentication
 * @returns {WfAuthHeaders}
 */
export function getAuthHeaders() {
  const headers = {}

  const token = getXSRFToken()
  const sessionID = getSessionID()
  const authorizationHeader = getAuthorizationHeader()

  if (token) {
    headers['X-XSRF-TOKEN'] = token
  } else if (sessionID) {
    headers.sessionID = sessionID
  }

  if (authorizationHeader) {
    headers.Authorization = authorizationHeader
  }

  return headers
}

export function getRedrockHeaders() {
  return {
    'X-Requested-With': 'XMLHttpRequest',
    ...getAuthHeaders(),
  }
}

export function getRequestIdHeaders() {
  const requestId = crypto?.randomUUID?.().replaceAll('-', '')

  return requestId ? { 'x-request-id': requestId } : {}
}

/**
 * Returns an accept language header to support unified shell localization
 */
export function getAcceptLanguageHeader() {
  const acceptLanguage = window.WF_LOCALIZER?.ACCEPT_LANGUAGE_HEADER

  return acceptLanguage
    ? {
        'Accept-Language': acceptLanguage,
      }
    : {}
}

export const FORM_CONTENT_TYPE_HEADER = {
  'Content-Type': CONTENT_TYPE_FORM,
}

export const JSON_CONTENT_TYPE_HEADER = {
  'Content-Type': CONTENT_TYPE_JSON,
}
