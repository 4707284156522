import { trackLaunchNetworkEvent } from '@wf-mfe/logger'
import { GET, POST } from './constants.js'

export function getInitialTrackingObject(fetchOptions) {
  return {
    method:
      fetchOptions.method || (fetchOptions.body === undefined ? GET : POST),
  }
}

export function trackRequest(url, tracking) {
  return trackLaunchNetworkEvent(url, tracking.method)
}
