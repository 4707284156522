const ONE_DAY = 1e3 * 60 * 60 * 24
export const DEFAULT_MAX_MEMORY_SIZE = 1e8

export class MaxMemoryTTLRequestStore {
  store = new Map()
  timeouts = new Map()
  cacheMemorySize = 0

  constructor(maxMemorySize = DEFAULT_MAX_MEMORY_SIZE) {
    this.maxMemorySize = maxMemorySize
  }

  delete(key) {
    const item = this.store.get(key)
    this.cacheMemorySize -= item?.size || 0
    clearTimeout(this.timeouts.get(key))
    this.store.delete(key)
    this.timeouts.delete(key)
  }

  get(key) {
    return this.store.get(key)?.getValue()
  }

  has(key) {
    return this.store.has(key)
  }

  set(key, value, ttl = ONE_DAY) {
    clearTimeout(this.timeouts.get(key))
    this.store.set(
      key,
      new MaxMemoryTTLRequestStoreItem(
        key,
        value,
        ttl,
        this.cacheMemorySize,
        this.updateCacheSizeAndRecoverSpace.bind(this),
      ),
    )

    this.timeouts.set(
      key,
      setTimeout(() => {
        this.delete(key)
      }, ttl),
    )
  }

  hasSizeOverage() {
    return this.cacheMemorySize > this.maxMemorySize
  }

  updateCacheSizeAndRecoverSpace(size) {
    this.cacheMemorySize += size

    if (this.hasSizeOverage()) {
      const sortedBySizeCost = Array.from(this.store.values()).sort((a, b) => {
        return a.getStorageCost() > b.getStorageCost() ? 1 : -1
      })

      while (this.hasSizeOverage() && sortedBySizeCost.length) {
        this.delete(sortedBySizeCost.pop().key)
      }
    }
  }
}

class MaxMemoryTTLRequestStoreItem {
  constructor(key, value, ttl, cacheMemorySize, cb) {
    this.cacheMemorySize = cacheMemorySize
    this.key = key
    this.value = value
    this.ttl = ttl
    this.entryTime = performance.now()
    this.accessCount = 1
    this.lastAccessedTime = this.entryTime
    this.size = 0
    this.setSizeFromResponse().then(cb)
  }

  async setSizeFromResponse() {
    const response = await this.value
    const clonedResponse = await response.clone()
    const blob = await clonedResponse.blob()
    this.size = blob.size
    return this.size
  }

  getValue() {
    this.accessCount++
    this.lastAccessedTime = performance.now()
    return this.value
  }

  getStorageCost() {
    return getStorageCost(this)
  }
}

export function getStorageCost(item) {
  if (item.size === 0) {
    return 0
  }

  if (item.size > item.maxMemorySize) {
    return Number.POSITIVE_INFINITY
  }

  const currentTime = performance.now()
  const timeSinceLastAccess = currentTime - item.lastAccessedTime
  const timeSinceEntry = currentTime - item.entryTime

  const aboutToExpireRatio = timeSinceEntry / item.ttl
  const accessCountRatio = 1 / item.accessCount
  const lastAccessedTimeToCacheTimeRatio = timeSinceLastAccess / item.ttl
  const lastAccessedToEntryTimeRatioWeightedBySize =
    timeSinceLastAccess / (Math.log(item.size) * timeSinceEntry)

  return (
    item.size *
    aboutToExpireRatio *
    accessCountRatio *
    lastAccessedTimeToCacheTimeRatio *
    lastAccessedToEntryTimeRatioWeightedBySize
  )
}
