/**
 * A function that returns an URI that can be used in /attask/api-internal/batch calls.
 *
 * Note: This function's signature is nearly identical to wfetch and many RedRock api calls that often happen concurrently (or very close to concurrently) in your applications can be made into batch calls simply by gathering up your wfetch calls and changing them to wfetchUri and then making a single wfetch call.
 *
 * @param {string} url - endpoint url to make the request to
 * @param {RequestInit} [fetchOptions] - fetch-compatible options object
 * @returns {string}  uri
 */

export function wfetchUri(url, fetchOptions = {}) {
  const { body, method = 'GET' } = fetchOptions
  const [baseUrl, searchString] = url.split('?')
  const searchParams = new URLSearchParams(searchString)

  if (body) {
    for (const [key, value] of Object.entries(body)) {
      searchParams.append(key, value)
    }
  }

  if (!searchParams.has('method')) {
    searchParams.set('method', method)
  }

  return `${baseUrl}?${searchParams.toString()}`
}
