import { WfetchCache } from './caching/WfetchCache.js'
import { getCacheKey } from './caching/getCacheKey.js'
import { getWorkfrontRootApiUrl } from './configuration'
import getFetchOptions from './getFetchOptions.js'
import {
  reauthenticateIms,
  redirectToLogin,
  shouldRedirectToLogin,
} from './redirectToLogin.js'
import {
  isQssUiDataUrl,
  isRedRockUrl,
  isSpringControllerUrl,
  isStreamApiUrl,
} from './requestTypes.js'
import { getInitialTrackingObject, trackRequest } from './trackingFetch.js'
import { unwrapResponse } from './utilities.js'

/**
 * Wrapped fetch call that can communicate with the Stream API,
 * Spring Controller and other endpoints.
 * Redirects to login if user is not authenticated.
 * @param {string} url   endpoint url to make the request to
 * @param {RequestInit} [fetchOptions]   fetch-compatible options object
 * @param {object} [workfrontOptions]   Workfront-specific options object
 * @returns {Promise<String|Object> | never}   Unwraps response based on request type (json or text)
 */
export async function wfetch(url, fetchOptions = {}, workfrontOptions = {}) {
  const workfrontRequest = new WorkfrontRequest(
    url,
    fetchOptions,
    workfrontOptions,
  )

  return workfrontRequest.send()
}

export class WorkfrontRequest {
  static responsePassThrough = (response) => response

  constructor(url, fetchOptions, workfrontOptions) {
    this.url = url
    this.fullUrl = getWorkfrontRootApiUrl() + url
    const tracking = getInitialTrackingObject(fetchOptions)

    this.trackRequest = () => {
      trackRequest(url, tracking)
    }
    this.mergedFetchOptions = getFetchOptions(url, fetchOptions, tracking)

    let wfOptionsUsingInitialCall

    if (window.qsBootstrapCalls?.[url]) {
      // purposefully not passing in workfrontOptions here
      // the initial calls in quicksilver-server don't have those options
      // and we need to be able to compare the wfetch call to the initial call
      const cacheKey = getCacheKey(url, this.mergedFetchOptions)

      if (window.qsBootstrapCalls[url].cacheKey === cacheKey) {
        wfOptionsUsingInitialCall = {
          ...workfrontOptions,
          initialRequest: window.qsBootstrapCalls[url],
          timeToExpiration: 5e3,
          deleteAfterUse: () => delete window.qsBootstrapCalls[url],
        }
      }
    }

    this.cache = new WfetchCache(
      this.fullUrl,
      this.mergedFetchOptions,
      wfOptionsUsingInitialCall || workfrontOptions,
    )

    this.shouldRedirectResponseToLogin = (response) =>
      shouldRedirectToLogin(response) && !workfrontOptions.preventAutoRedirect
    this.handleErrorResponse = workfrontOptions.handleErrorResponse
    this.handleOkResponse = workfrontOptions.handleOkResponse
  }

  async send() {
    const cachedData = this.cache.getCachedData()
    if (cachedData) {
      return cachedData
    }

    try {
      const response = await this.fetchWithRetry()
      this.trackRequest()

      if (this.shouldRedirectResponseToLogin(response)) {
        return await redirectToLogin(this.fullUrl)
      }

      if (response.ok) {
        return await this.getSuccessHandler()(response)
      }

      if (this.handleErrorResponse) {
        return this.handleErrorResponse(response)
      }

      return await this.getErrorHandler()(response)
    } catch (error) {
      throw error instanceof Error ? error : new Error(error)
    }
  }

  getCachedOrNewResponse() {
    return (
      this.cache.getCachedResponse() ||
      fetch(this.getUrlWithOptionalPublicToken(), this.mergedFetchOptions)
    )
  }

  getUrlWithOptionalPublicToken() {
    const publicToken = window.config?.publicToken

    if (!publicToken) {
      return this.fullUrl
    }

    const [url, query] = this.fullUrl.split('?')
    const params = new URLSearchParams(query)
    params.set('publicToken', publicToken)
    const fullUrlWithPublicToken = `${url}?${params.toString()}`

    return fullUrlWithPublicToken
  }

  async fetchWithRetry() {
    const response = await this.getCachedOrNewResponse()

    if (this.shouldRedirectResponseToLogin(response)) {
      const reauthenticated = await reauthenticateIms()
      if (reauthenticated) {
        this.cache.clearCachedResponse()
        return this.getCachedOrNewResponse()
      }
    }

    return response
  }

  getErrorHandler() {
    return async (response) => {
      let message = response.statusText
      let errorData

      try {
        errorData = await response.json()
        message = errorData?.error?.message
      } catch (error) {
        console.error(error)
      }

      const responseError = new Error(
        `\n\t${response.status}: ${message}\n\t${this.url}`,
      )

      responseError.response = response
      responseError.errorData = errorData
      responseError.status = response.status

      throw responseError
    }
  }

  getSuccessHandler() {
    let handleResponseFunction = this.handleOkResponse

    if (handleResponseFunction === undefined && isRedRockUrl(this.url)) {
      handleResponseFunction = this.unwrapRedRockResponse
    }

    if (handleResponseFunction !== undefined) {
      return async (response) => {
        const responseData = await handleResponseFunction.call(this, response)

        this.cache.cacheResponseData(responseData)

        return responseData
      }
    }

    return WorkfrontRequest.responsePassThrough
  }

  async unwrapRedRockResponse(response) {
    const parsedData = await unwrapResponse(response)
    let responseData

    if (isStreamApiUrl(this.url)) {
      if (parsedData.error) {
        throw errorWithTitle(parsedData.error.message, parsedData.error.title)
      }

      responseData = getActualDataForStreamApiRespnse(parsedData)

      if (this.url.includes('/attask/api-internal/batch')) {
        responseData = responseData.map(getActualDataForStreamApiRespnse)
      }
    } else if (isSpringControllerUrl(this.url)) {
      if (parsedData.error) {
        throw errorWithTitle(parsedData.error.message, parsedData.error.title)
      }

      responseData = getActualDataForSpringControllerResponse(parsedData)
    } else if (isQssUiDataUrl(this.url)) {
      if (parsedData.error) {
        throw errorWithTitle(parsedData.error.message, parsedData.error.title)
      }

      responseData = parsedData
    }

    return responseData
  }
}

function getActualDataForStreamApiRespnse(parsedData) {
  return parsedData?.data?.result ?? parsedData?.data ?? parsedData
}

function getActualDataForSpringControllerResponse(parsedData) {
  return parsedData?.data?.data ?? parsedData?.data ?? parsedData
}

function errorWithTitle(message, title) {
  const error = new Error(message)
  error.title = title
  return error
}
